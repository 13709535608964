<template>
  <!-- counter -->
  <div>
    <v-container grid-list-lg class="carder" fluid>
      <div></div>
      <v-tabs
        centered
        color="blue darken-1"
        background-color="transparent"
        grow
      >
        <v-tab
          v-for="(value, index) in navigation"
          :key="index"
          :to="value.path"
          >{{ value.title }}</v-tab
        >
      </v-tabs>
      <div v-for="(value, index) in navigation" :key="index" class="min-h">
        <div v-if="Route === value.name">
          <component :is="value.component" />
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ImpersonateSettings",

  data() {
    return {
      navigation: [
        {
          name: "detail",
          path: "/impersonate/settings/detail",
          title: "Business Detail",
          component: () => import("./Business-detail"),
        },
        {
          name: "orders",
          path: "/impersonate/settings/orders",
          title: "Orders",
          component: () => import("./Orders"),
        },
        {
          name: "miscellaneous",
          path: "/impersonate/settings/miscellaneous",
          title: "Miscellaneous",
          component: () => import("./Miscellaneous"),
        },
        // {
        //   name: "outlets",
        //   path: "/impersonate/overall/outlets",
        //   title: "Outlets",
        //   component: () => import("./components/Outlets"),
        // },
      ],
      Route: this.$route.params.param,
      // {
      //   name: "Melissa",
      //   store: "Miko cafe",
      // },
    };
  },

  watch: {
    $route: "updateRoute",
  },

  methods: {
    updateRoute() {
      this.Route = this.$route.params.param;
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.pad {
  margin-top: 3px;
}
.min-h {
  max-height: 81vh;
  overflow-y: auto;
}
.w-75 {
  width: 75%;
}
</style>
